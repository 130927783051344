import * as AuthActions from './auth.actions';

export interface AuthState {
  token: string | null;
  initialized: boolean;
  user: string | null;
  authError: string | null;
}

const initialState: AuthState = {
  token: null,
  initialized: false,
  user: null,
  authError: null
}

export function authReducer(
  state: AuthState = initialState,
  action: AuthActions.AuthActions
) {
  switch (action.type) {
    case AuthActions.LOGIN_START:
      return {
        ...state,
        initialized: false,
        authError: null
      };
    case AuthActions.AUTO_LOGIN:
      return {
        ...state,
        initialized: false,
        token: null
      };
    case AuthActions.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        initialized: true,
        token: action.payload.token,
        user: action.payload.user
      };
    case AuthActions.AUTHENTICATE_FAIL:
      return {
        ...state,
        token: null,
        initialized: true,
        authError: action.payload
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        initialized: true,
        token: null,
        user: null
      };
    default:
      return state;
  }
}
