import {Action} from '@ngrx/store';

export const SET_WORKSPACE = '[Workspace] Set Workspace';
export const WORKSPACE_SUCCESS = '[Workspace] Workspace Success';
export const WORKSPACE_FAIL = '[Workspace] Workspace Fail';

export class SetWorkspace implements Action {
  readonly type = SET_WORKSPACE;

  constructor(
    public payload: string
  ) {
  }
}

export class WorkspaceSuccess implements Action {
  readonly type = WORKSPACE_SUCCESS;

  constructor() {
  }
}

export class WorkspaceFail implements Action {
  readonly type = WORKSPACE_FAIL;

  constructor() {
  }
}

export type WorkspaceActions =
  SetWorkspace |
  WorkspaceSuccess |
  WorkspaceFail;
