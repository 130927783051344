import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';

import * as WorkspaceActions from './workspace.actions';

@Injectable()
export class WorkspaceEffects {
  constructor(
    private actions$: Actions
  ) {
  }

  workspaceSet = createEffect(
    () => this.actions$.pipe(
      ofType(WorkspaceActions.SET_WORKSPACE),
      map((action: WorkspaceActions.SetWorkspace) => {
        if (action.payload.length < 4) {
          return new WorkspaceActions.WorkspaceFail();
        }
        return new WorkspaceActions.WorkspaceSuccess();
      })
    )
  );
}
