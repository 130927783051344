import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {switchMap, catchError, map, tap, withLatestFrom} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {of} from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import {environment} from '@environments/environment';
import {selectWorkspace} from '@app/_store/workspace/workspace.selectors';
import * as fromApp from '@app/_store/app.reducer';

interface AuthResponseData {
  token: string;
  workspace: string;
}

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>) {
  }

  parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  authLogin = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.LOGIN_START),
      switchMap((authData: AuthActions.LoginStart) => {
        return this.http
          .post<AuthResponseData>(
            environment.url_login,
            {
              username: authData.payload.username,
              password: authData.payload.password
            },
            {
              withCredentials: true
            }
          )
          .pipe(
            map(resData => {
              const decoded = this.parseJwt(resData.token);
              const userId = decoded.sub;

              return new AuthActions.AuthenticateSuccess({
                token: resData.token,
                user: userId,
                workspace: resData.workspace
              });
            }),
            catchError(error => {
              // Todo: add handle-error
              // Todo: de acuerdo al error (http code), mostrar mensaje
              return of(new AuthActions.AuthenticateFail(error));
            })
          );
      })
    )
  );

  autoLogin = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.AUTO_LOGIN),
      switchMap(() => {
        const sessionInvalidated = localStorage.getItem('sessionInvalidated');
        if (sessionInvalidated) {
          return of(new AuthActions.Logout());
        }

        const ss = sessionStorage.getItem('session');
        console.log('session', ss);
        if (!ss) {
          return of(new AuthActions.Logout());
        }

        return this.http
          .get<AuthResponseData>(
            environment.url_session_token,
            {
              withCredentials: true ,
              observe: 'response'
            }
          )
          .pipe(
            map(response => {
              if (response.status == 204) {
                return new AuthActions.AuthenticateFail('No session token found');
              }

              const resData = response.body;
              if (!resData) {
                throw new Error('Response data is empty');
              }

              const decoded = this.parseJwt(resData.token);
              const userId = decoded.sub;

              // console.log('sub:', decoded.sub);
              // console.log('email:', decoded.email);

              return new AuthActions.AuthenticateSuccess({
                token: resData.token,
                user: userId,
                workspace: resData.workspace
              });
            }),
            catchError(error => {
              if (error.status === 0) {
                return of(new AuthActions.AuthenticateFail(error));
              }

              return of(new AuthActions.Logout());
            })
          );
      })
    )
  );

  authLogout = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.LOGOUT),
      switchMap(() =>
        this.http.get(environment.url_logout, { withCredentials: true }).pipe(
          tap(() => {
            localStorage.removeItem('sessionInvalidated');
          }),
          catchError(() => {
            localStorage.setItem('sessionInvalidated', 'true');
            return of(null);
          })
        )
      ),
    ),
    { dispatch: false }
  );

  authRedirect = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.AUTHENTICATE_SUCCESS),
      withLatestFrom(this.store.pipe(select(selectWorkspace))),
      switchMap(([authData, currentWorkspace]: [AuthActions.AuthenticateSuccess, string]) => {
        const targetWorkspace = authData.payload.workspace;

        if (currentWorkspace === targetWorkspace) {
          sessionStorage.setItem('session', 'true');
          return of({type: 'NO REDIRECT'});
        }

        const hostname = window.location.hostname;
        const port = window.location.port;

        let domain = '';
        const split = hostname.split('.');

        if (split[split.length - 1] === 'localhost') {
          domain = 'localhost';
        }

        if (domain === '' && split.length > 1) {
          domain = split[split.length - 2] + '.' + split[split.length - 1];
        }

        const url_redirect = 'https://' + targetWorkspace + '.' + domain + (port ? ':' + port : '');
        window.location.replace(url_redirect);

        return of({type: 'REDIRECT'});
      })
    )
  );
}
