import {CanActivateFn, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {inject} from '@angular/core';

import * as fromApp from '@app/_store/app.reducer';
import {isAuthenticated, selectAuthState} from '@app/_modules/_auth/_store/auth.selectors';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {take} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const authGuard: CanActivateFn = (route, state) => {
  const store: Store<fromApp.AppState> = inject(Store);
  const router: Router = inject(Router);

  // console.log('Auth guard triggered');

  return store.select(selectAuthState).pipe(
    tap(authState => console.log('Current auth state:', authState)),
    filter(authState => authState.initialized), // Espera hasta que el estado de autenticación esté inicializado
    take(1), // Toma solo el primer valor emitido después de la inicialización
    switchMap(() => store.select(isAuthenticated)),
    tap(authenticated => console.log('Authentication state:', authenticated)),
    map((authenticated: boolean) => {
      if (!authenticated) {
        console.log('User is not authenticated, redirecting to auth page');
        router.navigate(['/auth']);
      }
      return authenticated;
    })
  );
};

