import {SearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {
  CustomerType2LabelMapping,
  ICustomer,
  IdentificationType2LabelMapping,
  ISearchCustomer
} from '@app/_modules/_customers/_models/customer.interface';
import {ApiCustomerService} from '@app/_modules/_customers/_services/api-customer.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable(
  {providedIn: 'root'}
)
export class CustomerSearchStrategyService implements SearchStrategy<ISearchCustomer> {

  columns = [
    {width: 'w-32', textAlign: 'text-left', label: 'Código / Nombre', key: 'entity_code'},
    {width: 'w-28', textAlign: 'text-left', label: 'IDENT.', key: 'identification'},
    {width: 'w-14', textAlign: 'text-center', label: 'P/E', key: 'customer_type'},
    {width: 'w-10', textAlign: 'text-center', label: 'T.ID', key: 'cod_type_ident'},
    {width: 'w-10', textAlign: 'text-center', label: 'RUC', key: 'with_ruc'}
  ];

  constructor(private apiCustomerService: ApiCustomerService) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFilteredData(query: string): Observable<ISearchCustomer[]> {
    return this.apiCustomerService.getCustomerList().pipe(
      map((customers: ICustomer[]) =>
        customers.map(customer => {
          const personName = `${customer.first_name} ${customer.last_name}`.trim();
          const entityName = personName
            ? `${personName} - ${customer.commercial_name}`
            : customer.commercial_name + '';

          return {
            entity_code: customer.code,
            entity_name: entityName,
            identification: customer.identification,
            customer_type: CustomerType2LabelMapping[customer.customer_type].charAt(0),
            cod_type_ident: IdentificationType2LabelMapping[customer.cod_type_ident].slice(0, 6),
            with_ruc: customer.with_ruc ? 'S' : 'N'
          }
        })
      )
    );
  }
}
