import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.module';
import * as fromApp from './_store/app.reducer';
import { AuthEffects } from './_modules/_auth/_store/auth.effects';
import { WorkspaceEffects } from '@app/_store/workspace/workspace.effects';
import {AuthInterceptorService} from '@app/_services/auth-interceptor.service';
import { ModalSearchComponent } from './_components/modal-search.component';
import {FormsModule} from '@angular/forms';
import { NotificationsComponent } from './_components/notifications.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    ModalSearchComponent,
    NotificationsComponent,
    ],
    exports: [],
    bootstrap: [AppComponent],
    imports: [BrowserModule,
        AppRoutingModule,
        StoreModule.forRoot(fromApp.appReducer),
        EffectsModule.forRoot([AuthEffects, WorkspaceEffects]),
        StoreDevtoolsModule.instrument({ logOnly: true }),
        FormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
