import {Observable} from 'rxjs';
import {ISearchProduct} from '@app/_modules/_products/_models/product.interface';
import {ISearchCustomer} from '@app/_modules/_customers/_models/customer.interface';
import {ISearchGroup} from '@app/_modules/_groups/_models/group.interface';
import {ISearchInventoryInbound} from '@app/_modules/_inventory/_models/inbound.interface';
import {ISearchInventoryOutbound} from '@app/_modules/_inventory/_models/outbound.interface';

export interface ISearchResult {
  [key: string]: string | number;
  entity_code: string;
  entity_name: string;
}

export interface ColumnMetadata {
  key: string;
  label: string;
  width: string;
  textAlign: string;
}

export interface SearchStrategy<T> {
  columns: ColumnMetadata[];

  getFilteredData(query: string): Observable<T[]>;
}

export class ContextSearchStrategy {
  constructor(public searchStrategy: SearchStrategy<
    ISearchProduct |
    ISearchCustomer |
    ISearchGroup |
    ISearchInventoryInbound |
    ISearchInventoryOutbound
  >) {
  }
}
