import * as WorkspaceActions from '@app/_store/workspace/workspace.actions';

export interface WorkspaceState {
  workspace: string;
}

const initialState: WorkspaceState = {
  workspace: ''
}

export function workspaceReducer(
  state: WorkspaceState = initialState,
  action: WorkspaceActions.WorkspaceActions
) {
  switch (action.type) {
    case WorkspaceActions.SET_WORKSPACE:
      return {
        ...state,
        workspace: action.payload
      };
    case WorkspaceActions.WORKSPACE_SUCCESS:
      return {
        ...state
      }
    case WorkspaceActions.WORKSPACE_FAIL:
      return {
        ...state,
        workspace: ''
      }
    default:
      return state;
  }
}
