import {SearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {IProduct, ISearchProduct, ISimpleProduct} from '@app/_modules/_products/_models/product.interface';
import {ApiProductService} from '@app/_modules/_products/_services/api-product.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable(
  {providedIn: 'root'}
)
export class ProductSearchStrategyService implements SearchStrategy<ISearchProduct> {

  columns = [
    {width: 'w-36', textAlign: 'text-left', label: 'Código / Nombre', key: 'entity_code'},
    {width: 'w-14', textAlign: 'text-right', label: 'Cant.', key: 'stock'},
    {width: 'w-14', textAlign: 'text-right', label: 'PVP', key: 'selling_price'},
    {width: 'w-10', textAlign: 'text-center', label: 'IVA', key: 'iva_tax'},
    {width: 'w-16', textAlign: 'text-right', label: 'P.Compra', key: 'purchase_price'},
    {width: 'w-16', textAlign: 'text-right', label: 'Costo', key: 'cost_av'},
    {width: 'w-16', textAlign: 'text-right', label: 'Grupo', key: 'group_code'}
  ];

  constructor(private apiProductService: ApiProductService) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFilteredData(query: string): Observable<ISearchProduct[]> {
    return this.apiProductService.getProductList().pipe(
      map((products: IProduct[]) =>
        products.map(product => {
          const hasIvaTax = product.taxes.iva_tax?.cod === '2';
          const firstSellingPrice = Number(product.selling_prices?.[0]?.final_price ?? 0).toFixed(2);

          let product_name = '<div class="grow ">'+product.name+'</div><div class="flex-none w-40 truncate">Grupo: '+product.group_name+'</div>';

          return {
            entity_code: product.code,
            entity_name: product_name,
            stock: product.stock,
            iva_tax: hasIvaTax ? 'S' : 'N',
            selling_price: firstSellingPrice,
            purchase_price: product.purchase_price,
            cost_av: product.average_cost,
            group_code: product.group_code,
          };
        })
      )
    );
  }
}
