import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ContextSearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {debounceTime, from, Subject, takeUntil} from 'rxjs';
import {distinctUntilChanged, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html'
})
export class ModalSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  query: string = '';
  newQuery: string = '';
  private querySubject = new Subject<string>();
  private unsubscribe$ = new Subject<void>();

  activeIndex: number = 0;
  activeHandlerBlur: boolean = true;
  flagScroll: boolean = false;

  @ViewChild('inputSearch') inputElement!: ElementRef;
  @ViewChild('options') optionsList!: ElementRef;
  @Output() completed = new EventEmitter<string>();

  resultAux: any[] = [];
  result: any[] = [];

  contextSearchStrategy!: ContextSearchStrategy;

  constructor() {
  }

  ngOnInit() {
    this.querySubject.pipe(
      debounceTime(2),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe((newQuery) => {
      this.query = newQuery;
      this.result = this.filteredEntities();
      this.activeIndex = 0;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isAfterViewInit: boolean = false;
  ngAfterViewInit() {
    Promise.resolve().then(() => this.inputElement.nativeElement.focus());
    this.isAfterViewInit = true;
  }

  handleBlur() {
    if (this.activeHandlerBlur)
      this.inputElement.nativeElement.focus();
  }

  updateQuery() {
    this.querySubject.next(this.newQuery);
  }

  async search() {
    this.contextSearchStrategy.searchStrategy.getFilteredData('').pipe(
      switchMap((entities) => {
        this.resultAux = [];
        return from(entities);
      }),
      tap((entity) => {
        this.resultAux.push(entity);
        this.result = this.resultAux.slice(0, 25);
      })
    ).subscribe();
  }

  private normalizeString(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
  }

  private filterEntities(query: string, result: any[]): any[] {
    const normalizedQuery = this.normalizeString(query);
    const queryWords = normalizedQuery.split(/\s+/);

    return result.filter(p => {
      const normalizedEntityName = this.normalizeString(p.entity_name);
      const normalizedEntityCode = this.normalizeString(p.entity_code);
      const normalizedEntityAttributes = normalizedEntityName + ' ' + normalizedEntityCode;
      return queryWords.every(queryWord => normalizedEntityAttributes.includes(queryWord));
    });
  }

  private filteredEntities() {
    if (this.query.length > 0) {
      return this.filterEntities(this.query, this.resultAux);
    }

    return this.resultAux.slice(0, 25);
  }

  onScroll(event: Event) {
    if (this.query.length > 0) return;

    const inputTarget = event.target as HTMLInputElement;
    const threshold = 100;
    const position = inputTarget.scrollHeight - (inputTarget.scrollTop + inputTarget.clientHeight);

    if (position < threshold) {
      const moreItems = this.result.length + 25;
      this.result = this.resultAux.slice(0, moreItems > this.resultAux.length ? this.resultAux.length : moreItems);
    }
  }

  setActiveIndex(newIndex: number, event?: MouseEvent): void {
    if (this.flagScroll && event && event.type === 'mouseover') {
      this.flagScroll = false;
      return;
    }

    this.activeIndex = newIndex;

    setTimeout(() => {
      const itemElement = this.optionsList.nativeElement.children[this.activeIndex];
      const listElement = this.optionsList.nativeElement;

      if (itemElement && listElement) {
        const itemTop = itemElement.getBoundingClientRect().top;
        const itemBottom = itemElement.getBoundingClientRect().bottom;
        const listTop = listElement.getBoundingClientRect().top;
        const listBottom = listElement.getBoundingClientRect().bottom;

        if (itemBottom > listBottom) {
          listElement.scrollTop += itemBottom - listBottom;
          this.flagScroll = true;
        } else if (itemTop < listTop) {
          listElement.scrollTop -= listTop - itemTop;
          this.flagScroll = true;
        }
      }
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {

    if (event.key === 'ArrowDown') {
      this.setActiveIndex(Math.min(this.result.length - 1, this.activeIndex + 1));
      return event.preventDefault();
    }

    if (event.key === 'ArrowUp') {
      this.setActiveIndex(Math.max(0, this.activeIndex - 1));
      return event.preventDefault();
    }

    if (event.key === 'Enter' && this.activeIndex >= 0 && this.isAfterViewInit) {
      this.selectActiveIndex();
      return event.preventDefault();
    }
  }

  selectActiveIndex() {
    if (this.result.length > 0 && this.activeIndex >= 0) {
      this.completed.emit(
        this.result[this.activeIndex].entity_code
      );
    }
  }

  closeModal() {
    this.completed.emit('');
  }
}
