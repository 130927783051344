import {ActionReducerMap} from '@ngrx/store';

import * as fromAuth from '@app/_modules/_auth/_store/auth.reducer';
import * as fromWorkspace from './workspace/workspace.reducer';

export interface AppState {
  auth: fromAuth.AuthState;
  workspace: fromWorkspace.WorkspaceState;
}

export const appReducer: ActionReducerMap<AppState, any> = {
  auth: fromAuth.authReducer,
  workspace: fromWorkspace.workspaceReducer
};
