import {Injectable, OnDestroy} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Observable, Subscription, throwError} from 'rxjs';

import * as fromApp from '@app/_store/app.reducer';
import {domain_api} from '@environments/environment';

import {selectAuthToken} from '@app/_modules/_auth/_store/auth.selectors';
import {selectWorkspaceState} from '@app/_store/workspace/workspace.selectors';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements OnDestroy, HttpInterceptor {
  private token!: string | null;
  private workspace!: string;
  private subs = new Subscription();

  constructor(private store: Store<fromApp.AppState>) {
    this.subs.add(
      this.store.select(selectAuthToken).subscribe(token => {
        this.token = token;
      })
    );

    this.subs.add(
      this.store.select(selectWorkspaceState).subscribe(workspaceState => {
        this.workspace = workspaceState.workspace;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleErrors = (error: HttpErrorResponse) => {
      if (error.status === 0) {
        // eslint-disable-next-line no-console
        console.log('No hay conexión a Internet');
      }
      return throwError(() => error);
    };

    if (!req.url.startsWith(domain_api)) {
      return next.handle(req).pipe(catchError(handleErrors));
    }

    if (this.token) {
      req = req.clone({
        headers: req.headers.append('Authorization', `Bearer ${this.token}`)
      });
    }

    if (this.workspace && this.workspace.length > 0) {
      req = req.clone({
        headers: req.headers.append('workspace', this.workspace)
      });
    }

    return next.handle(req).pipe(catchError(handleErrors));
  }
}
