import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import {ModalSearchComponent} from '@app/_components/modal-search.component';
import {Subscription} from 'rxjs';
import {ContextSearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalSearchService {
  modelSearchComponentRef!: ComponentRef<ModalSearchComponent>;
  isModalOpen: boolean = false;
  private subscription: Subscription = new Subscription();
  private inputTarget: HTMLInputElement | null = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) {}

  public openModal(inputTarget: HTMLInputElement, contextSearchStrategy: ContextSearchStrategy) {
    if (this.isModalOpen) {
      this.closeModal();
      return;
    }

    this.inputTarget = inputTarget;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalSearchComponent);
    const componentRef = componentFactory.create(this.injector);
    componentRef.instance.contextSearchStrategy = contextSearchStrategy;
    componentRef.instance.search();

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    this.subscription.add(
      componentRef.instance.completed.subscribe(response  => {
        this.closeModal();

        if (!this.inputTarget)
          return;

        if (this.inputTarget.readOnly)
          return;

        this.inputTarget.value = response;
        this.inputTarget.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true
          })
        );
      })
    );

    this.modelSearchComponentRef = componentRef;
    this.isModalOpen = true;
  }

  public closeModal() {
    if (!this.modelSearchComponentRef) {
      return;
    }

    this.modelSearchComponentRef.instance.activeHandlerBlur = false;

    this.appRef.detachView(this.modelSearchComponentRef.hostView);
    this.modelSearchComponentRef.destroy();

    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    this.isModalOpen = false;

    if (this.inputTarget)
      this.inputTarget.focus();
  }
}
