import {NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {authGuard} from '@app/_helpers/auth.guard';
import {loginGuard} from '@app/_helpers/login.guard';
import {redirectGuard} from '@app/_helpers/redirect.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./_modules/_dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [authGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./_modules/_auth/auth.module').then(m => m.AuthModule),
    canActivate: [loginGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./_modules/_auth/auth.module').then(m => m.AuthModule),
    canActivate: [redirectGuard]
  },
  {
    path: '**',
    loadChildren: () => import('./_modules/_auth/auth.module').then(m => m.AuthModule),
    canActivate: [redirectGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
//
// export const appRoutingModule = RouterModule.forRoot(routes);
