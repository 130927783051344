import { Injectable } from '@angular/core';
import {SearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {IInbound, ISearchInventoryInbound} from '@app/_modules/_inventory/_models/inbound.interface';
import {ApiInventoryInboundService} from '@app/_modules/_inventory/_services/api-inventory-inbound.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryInboundStrategyServiceTsService implements SearchStrategy<ISearchInventoryInbound> {
  columns = [
    {width: 'w-36', textAlign: 'text-left', label: '# Ingreso / Motivo', key: 'entity_code'},
    {width: 'w-28', textAlign: 'text-left', label: 'fecha', key: 'inbound_date'}
  ]

  constructor(private apiInventoryInboundService: ApiInventoryInboundService) {

  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFilteredData(query: string): Observable<ISearchInventoryInbound[]> {
    return this.apiInventoryInboundService.getInboundList().pipe(
      map((inventoryInbound: IInbound[]) =>
        inventoryInbound.map(inventoryInbound => {
          return {
            entity_code: inventoryInbound.inbound_id.toString(),
            entity_name: inventoryInbound.reason,
            inbound_date: inventoryInbound.inbound_date.slice(0,10),
          };
        })
      )
    );
  }
}
