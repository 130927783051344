import {SearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {IGroup, ISearchGroup} from '@app/_modules/_groups/_models/group.interface';
import {ApiGroupService} from '@app/_modules/_groups/_services/api-group.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable(
  {providedIn: 'root'}
)
export class GroupSearchStrategyService implements SearchStrategy<ISearchGroup> {

  columns = [
    {width: 'w-36', textAlign: 'text-left', label: 'Código / Nombre', key: 'entity_code'},
  ]

  constructor(private apiGroupService: ApiGroupService) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFilteredData(query: string): Observable<ISearchGroup[]> {
    return this.apiGroupService.getGroupList().pipe(
      map((groups: IGroup[]) =>
        groups.map(group => {
          return {
            entity_code: group.code,
            entity_name: group.name + ' - ' + group.description.trim(),
            description: group.description,
            color: group.color
          };
        })
      )
    );
  }
}
