import { Injectable } from '@angular/core';
import {SearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {Observable} from 'rxjs';
import {ApiInventoryOutboundService} from '@app/_modules/_inventory/_services/api-inventory-outbound.service';
import {IOutbound, ISearchInventoryOutbound} from '@app/_modules/_inventory/_models/outbound.interface';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryOutboundStrategyServiceTsService implements SearchStrategy<ISearchInventoryOutbound> {

  columns = [
    {width: 'w-36', textAlign: 'text-left', label: '# Egreso / Motivo', key: 'entity_code'},
    {width: 'w-28', textAlign: 'text-left', label: 'fecha', key: 'inbound_date'}
  ]

  constructor(private apiInventoryOutboundService: ApiInventoryOutboundService) {

  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFilteredData(query: string): Observable<ISearchInventoryOutbound[]> {
    return this.apiInventoryOutboundService.getOutboundList().pipe(
      map((inventoryOutbound: IOutbound[]) =>
        inventoryOutbound.map(inventoryOutbound => {
          return {
            entity_code: inventoryOutbound.outbound_id.toString(),
            entity_name: inventoryOutbound.reason,
            outbound_date: inventoryOutbound.outbound_date.slice(0,10),
          };
        })
      )
    );
  }
}
