import { Injectable } from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalKeyboardShortcutsService {

  constructor() { }

  watchKeyboardShortcuts(): Observable<KeyboardEvent> {
    return fromEvent<KeyboardEvent>(document, 'keydown').pipe(
      filter(event => {
        const metaKey = event.metaKey || event.ctrlKey;
        const key = event.key;

        const isCmdL = metaKey && key === 'l';
        const isCmdS = metaKey && key === 's';
        const isCmdA = metaKey && key === 'a';

        const isCmdK = metaKey && key === 'k';
        const isCmdJ = metaKey && key === 'j';
        const isEsc = key === 'Escape';

        if (isCmdL || isCmdS || isCmdA || isCmdK || isCmdJ || isEsc) {
          event.preventDefault();
          // console.log('Keyboard shortcut:', key);
        }

        if (isCmdL || isCmdS || isCmdA) {
          return false;
        }

        return isCmdK || isCmdJ || isEsc;
      })
    );
  }
}
